<template>
  <v-container class="mt-3 px-sm-10 px-0 elevation-0" fluid>
    <PageHeader
      header-text="Imports"
      class="mb-7"
    >
      <template #subheader>
        View your active and previous imports
      </template>
    </PageHeader>

    <div v-if="currentImports.length" class="field__header mt-10">
      Active Imports
    </div>
    <ImportsTable
      v-if="currentImports.length"
      :imports="currentImports"
      @canceled="handleCanceledImport"
    />

    <div class="field__header mt-10">
      Previous Imports
    </div>
    <ImportsTable
      :imports="previousImports"
      :for-current-imports="false"
    />
    <PaginationButtons
      :current-page-number="importsPageNumber"
      :current-page-size="importsPageSize"
      :count-total="importsTotal"
      :page-sizes="[25, 50, 100]"
      @next-page="getImportsNextPage"
      @prev-page="getImportsPrevPage"
      @change-page-size="importsChangePageSize"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import ImportsTable from "@/views/Imports/components/ImportsTable";
import PaginationButtons from "@/sharedComponents/pagination/PaginationButtons";

export default {
  name: "ImportsView",
  metaInfo: {
    title: 'Imports'
  },
  components: {
    PageHeader,
    ImportsTable,
    PaginationButtons
  },
  data() {
    return {
      currentImports: [],
      previousImports: [],
      eventSource: null,
      completedStatuses: ['completed', 'canceled', 'failed'],
      previousStatuses: ['queued', 'preparing', 'analyzing', 'requires action', 'processing', 'finalizing'],
      importsPageNumber: 1,
      importsPageSize: 25,
      importsTotal: 0,
    };
  },
  async created() {
    await this.getPreviousImports();
    await this.getCurrentImports();

    this.eventSource = await this.$store.dispatch('mercure/imports_subscribe');
    if (!this.eventSource) {
      return;
    }

    this.eventSource.onmessage = (msg) => {
      this.$store.dispatch('mercure/update_imports_last_event', msg.lastEventId);

      const parsedMessage = JSON.parse(msg.data);

      const currentImportIndex = this.currentImports.findIndex(elem => elem.id === parsedMessage.id) ?? null;
      if (currentImportIndex === -1) {
        if (this.completedStatuses.includes(parsedMessage.status)) {
          this.getPreviousImports();
          return;
        }

        this.currentImports.unshift(parsedMessage);
        return;
      }

      if (this.completedStatuses.includes(parsedMessage.status)) {
        this.$delete(this.currentImports, currentImportIndex);
        this.getPreviousImports();

        this.$store.commit('snackbar/showMessage', {
          color: "success",
          content: `An import has completed. Please check the section below for the results.`
        })
        return;
      }

      this.$set(this.currentImports, currentImportIndex, parsedMessage);
    };
  },
  beforeDestroy() {
    if (!this.eventSource) {
      return;
    }
    this.$store.dispatch('mercure/unsubscribe', this.eventSource)
  },
  methods: {
    async getPreviousImports() {
      const resp = await this.$rest.imports.get_collection({
        limit: this.importsPageSize,
        page: this.importsPageNumber,
        status: this.completedStatuses,
        sort: ['updatedAt:desc']
      });
      this.previousImports = resp.data.items;
      this.importsTotal = resp.data.totalCount;
    },
    async getCurrentImports() {
      const resp = await this.$rest.imports.get_collection({
        status: this.previousStatuses,
        sort: ['createdAt:desc']
      });
      this.currentImports = resp.data.items;
    },
    async handleCanceledImport() {
      await this.getCurrentImports();
      await this.getPreviousImports();
    },
    async getImportsNextPage() {
      this.importsPageNumber += 1;
      await this.getPreviousImports();
    },
    async getImportsPrevPage() {
      this.importsPageNumber -= 1;
      await this.getPreviousImports();
    },
    async importsChangePageSize(size) {
      this.importsPageSize = size;
      this.importsPageNumber = 1;
      await this.getPreviousImports();
    }
  },
};
</script>

<style lang="scss" scoped>
.mobile-hint {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #66788e;
}

.field {
  &__header {
    margin-bottom: 25px;
  }
}
</style>
